<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{i18n('Directory.AdDomainManagement')}}
      </span>
    </div>

    <article class="userTable">
      <!-- <div class="filter-block">
        <div class="filter-btn-wrap">
          <button
            type="button"
            class="btn btn-blue"
            data-toggle="modal"
            data-target="#ad"
            @click="openModal(true)"
          >
            新增
          </button>
        </div>
      </div> -->

      <div class="d-flex justify-content-end mb-2">
        <button
          type="button"
          class="btn btn-blue"
          data-toggle="modal"
          data-target="#ad"
          @click="openModal(true)"
        >
          {{i18n('Basic.Add')}}
        </button>
      </div>

      <vue-bootstrap4-table
        :rows="rows"
        :columns="columns"
        :config="config"
        :classes="classes"
      >
        <template slot="empty-results">
          {{i18n('Basic.EmptyResult')}} </template>
        <template slot="edit" slot-scope="props">
          <button
            class="btn btn-sm btn-yellow"
            data-toggle="modal"
            data-target="#ad"
            @click="openModal(false, props.row)"
          >
            {{i18n('Basic.Edit')}}
          </button>
        </template>

        <template slot="del" slot-scope="props">
          <button
            class="btn btn-sm btn-danger"
            data-toggle="modal"
            data-target="#delcommon"
            @click="openModal(false, props.row)"
          >
            {{i18n('Basic.Delete')}}
          </button>
        </template>
      </vue-bootstrap4-table>
    </article>

    <!-- 新增&編輯功能 Modal -->
    <div
      class="modal fade bd-example-modal-xl"
      id="ad"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header align-items-center">{{i18n('Directory.AdDomainManagement')}}</div>
          <div class="modal-body">
            <!-- <div class="field">
              <ValidationProvider
                tag="div"
                rules="required"
                v-slot="{ errors }"
                name="伺服器Port"
              >
                <label class="required-red"> 伺服器Port </label>
                <input
                  type="number"
                  name="伺服器Port"
                  :disabled="isNew === false"
                  v-model.number="item.ServerPort"
                />
                <span v-if="errors" class="text-danger font-weight-bold"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div> -->
            <div class="row">
              <div class="col-md-12">
                <div class="field">
                  <label class="field-name"> {{i18n('Directory.ServerName')}} </label>
                  <input
                    required
                    type="text"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="item.ServerName"
                  />
                </div>
              </div>
            </div>
            <!-- @keydown.stop.prevent.enter="saveData" -->
            <!-- <div class="field">
              <ValidationProvider
                tag="div"
                rules="required"
                v-slot="{ errors }"
                name="伺服器名稱"
              >
                <label class="required-red"> 伺服器名稱 </label>
                <input
                  type="text"
                  placeholder="請輸入伺服器名稱"
                  @compositionstart="is_composing = true"
                  @compositionend="is_composing = false"
                  v-model="item.ServerName"
                  @keydown.stop.prevent.enter="saveData"
                />
                <span
                  v-if="errors && !is_composing"
                  class="text-danger font-weight-bold"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div> -->

            <!-- <div class="field d-flex">
              <label class="required-red" for="UseSSL"> 是否使用SSL </label>
              <b-form-checkbox
                id="UseSSL"
                tag="span"
                v-model="item.UseSSL"
                class="ml-2 mr-n2"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </div> -->
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
              type="reset"
            >
              {{i18n('Basic.Close')}}
            </button>
            <button
              :disabled="item.ServerName === ''"
              class="btn btn-blue"
              type="button"
              @click="saveData"
            >
              {{i18n('Basic.Save')}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- del -->
    <div
      class="modal fade"
      id="delcommon"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header align-items-center">{{i18n('Basic.Delete')}}</div>
          <div class="modal-body">
            {{i18n('Custom.WhetherDeleteItem')}}<span style="font-weight: bolder">
              {{ item.ServerName }}
            </span>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal">{{i18n('Basic.Cancel')}}</button>
            <button class="btn btn-blue" data-dismiss="modal" @click="delItem">
              {{i18n('Basic.Delete')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapActions } from 'vuex';

export default {
  name: 'ActiveDirectory',
  data() {
    return {
      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      item: {
        UseSSL: true,
      },
      isNew: false,
      rows: [],
      is_composing: false,
      classes: {
        table: 'table-light table-striped',
        // via: 'table-danger'
      },
    };
  },
  computed:{
     columns(){
      const col =[
        {
          label: this.i18n('Directory.ServerName'),
          name: 'ServerName',
          sort: true,
          column_classes: 'fixedColumn',
          row_classes: 'fixedTd',
        },
        {
          label: this.i18n('Directory.Movers'),
          name: 'UpdateUser',
          sort: true,
          column_classes: 'fixedColumn',
          row_classes: 'fixedTd',
        },

        {
          label: '',
          name: 'edit',
          slot_name: 'edit',
          column_classes: 'fixedColumn_Btn',
          row_classes: 'fixedTd_Btn',
        },
        {
          label: '',
          name: 'del',
          slot_name: 'del',
          column_classes: 'fixedColumn_Btn',
          row_classes: 'fixedTd_Btn',
        },
      ]
      return col;
     }
  },
  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 取得所有資料***************************************************************
    getAllData() {
      const vm = this;
      const url = `${window.BaseUrl.api}/AD/domain`;
      this.updateLoading(true);
      vm.$http
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data);
            vm.rows = res.data.Data;
            // vm.rows.forEach((item) => {
            //   item.UseSSLCN = item.UseSSL ? "是" : "否";
            // });
            vm.updateLoading(false);
          }
        })
        .catch((error) => {
          vm.updateLoading(false);
          vm.$store.dispatch('alertMessageModule/updateMessage', {
            message: error,
            status: 'danger',
          });
        });
    },

    // 打開新增編輯刪除 Modal***************************************************************
    openModal(isNew, item) {
      const vm = this;
      if (!isNew) {
        vm.item = { ...item };
        vm.isNew = false;
      } else {
        vm.item = {
          UseSSL: true,
          ServerName: '',
        };
        vm.isNew = true;
      }
    },

    // 新增編輯**************************************************************************
    saveData() {
      const vm = this;
      const url = `${window.BaseUrl.api}/AD/domain`;
      let sendData = {
        ServerName: vm.item.ServerName,
        ServerPort: 0,
        UseSSL: false,
        CreateUser: localStorage.getItem('UserId'),
      };
      let httpMethods = 'post';
      if (!vm.isNew) {
        sendData = {
          ServerName: vm.item.ServerName,
          ServerPort: 0,
          UseSSL: false,
          DomainId: vm.item.DomainId,
          UpdateUser: localStorage.getItem('UserId'),
        };
        httpMethods = 'put';
      }
      this.updateLoading(true);
      vm.$http[httpMethods](url, sendData)
        .then((res) => {
          if (res.status === 200) {
            if (vm.isNew) {
              vm.$store.dispatch('alertMessageModule/updateMessage', {
                message: '新增成功',
                status: 'success',
              });
            } else {
              vm.$store.dispatch('alertMessageModule/updateMessage', {
                message: '編輯成功',
                status: 'success',
              });
            }
          }
          vm.getAllData();
          vm.updateLoading(false);
        })
        .catch((error) => {
          if (error) {
            vm.$store.dispatch('alertMessageModule/updateMessage', {
              message: error,
              status: 'danger',
            });
            vm.updateLoading(false);
          }
        });
      $('#ad').modal('hide');
    },

    // 刪除**************************************************************************
    delItem() {
      const vm = this;
      const url = `${window.BaseUrl.api}/AD/domain/${vm.item.DomainId}`;
      this.updateLoading(true);
      vm.$http
        .delete(url)
        .then((res) => {
          if (res.status === 200) {
            vm.$store.dispatch('alertMessageModule/updateMessage', {
              message: '刪除成功',
              status: 'success',
            });
            vm.getAllData();
            this.updateLoading(false);
          }
        })
        .catch((error) => {
          if (error) {
            vm.$store.dispatch('alertMessageModule/updateMessage', {
              message: error,
              status: 'danger',
            });
            vm.getAllData();
            this.updateLoading(false);
          }
        });
    },
  },
  mounted() {
    this.getAllData();
  },

  // 開著 Modal 時跳頁將黑色背景關閉
  destroyed() {
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  },
};
</script>
